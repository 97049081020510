export const ScreenSize = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

export const getScreenSize = (screenSize) => {
  if (screenSize >= 0 && screenSize <= 650) {
    return ScreenSize.xs;
  }

  if (screenSize > 650 && screenSize <= 960) {
    return ScreenSize.sm;
  }

  if (screenSize > 960 && screenSize <= 1280) {
    return ScreenSize.md;
  }

  if (screenSize > 1280 && screenSize < 1920) {
    return ScreenSize.lg;
  }

  if (screenSize >= 1920) {
    return ScreenSize.xl;
  }
};

export const isMobile = (screenSize) => ["xs", "sm"].includes(screenSize);
