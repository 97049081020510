import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Logo } from "../banner/Logo";
import Submit from "../pages/Submit";
import SubmitCriteria from "../pages/SubmitCriteria";

const useStyles = makeStyles({
  bannerTextContainer: {
    height: "60px",
    borderBottom: "1px solid #dedede",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 44px",
    alignItems: "center",
  },
  bannerText: {
    fontFamily: "Bodoni Moda, serif",
    alignSelf: "end",
    marginBottom: "5px",
  },
  cwdLogo: {
    width: "120px",
    marginTop: "7px",
  },
  submissionBanner: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    height: "60px",
    fontSize: "1.2rem",
  },
  submitLink: {
    fontFamily: "Bodoni Moda, serif",
    padding: "0px 8%",
    "&:hover": {
      color: "lightgray",
    },
    cursor: "pointer",
  },
  headerContainer: {},
});

export const WorldMapBanner = () => {
  const classes = useStyles();
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submitCriteriaOpen, setCriteriaOpen] = useState(false);

  const toggleSubmit = () => {
    setSubmitDialogOpen(!submitDialogOpen);
  };

  const toggleSubmissionCriteria = () => {
    setCriteriaOpen(!submitCriteriaOpen);
  };

  return (
    <div className={classes.submissionBanner}>
      <Submit open={submitDialogOpen} toggle={toggleSubmit} />
      <SubmitCriteria
        open={submitCriteriaOpen}
        toggle={toggleSubmissionCriteria}
      />
      <span onClick={toggleSubmit} className={classes.submitLink}>
        Submit a Destination
      </span>
      <span onClick={toggleSubmissionCriteria} className={classes.submitLink}>
        Submission Criteria
      </span>
      <div>
        <Logo name={"cwd"} className={classes.cwdLogo} />
      </div>
    </div>
  );
};
