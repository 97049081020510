import React, { useContext } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { store } from "../util/store";
import { isMobile } from "../util/screenSizeHelpers";

const useStyles = makeStyles({
  paper: {
    padding: (props) => (props.mobile ? "50px 10%" : "50px 20%"),
    overflow: "auto",
  },
  paragraph: {
    marginTop: "20px",
  },
  header: {
    fontWeight: "bold",
    marginTop: "50px",
  },
  title: {
    fontStyle: "italic",
  },
  name: {
    fontWeight: "bold",
    marginTop: "20px",
  },
});

const About = () => {
  const { state } = useContext(store);
  const mobile = isMobile(state.screenSize);
  const classes = useStyles({ mobile });
  const content = (
    <div className={classes.paper}>
      <Typography
        variant="h3"
        className={classes.header}
        style={{ marginTop: 0 }}
      >
        Mission
      </Typography>
      <Typography className={classes.paragraph}>
        The Museum of Ceramic Art - New York (MoCA/NY) was founded to advance
        the appreciation, understanding, and enjoyment of ceramics through
        exhibitions, research, education, and scholarship. MoCA/NY is currently
        operating as a virtual museum featuring online exhibitions, lectures,
        educational resources, and interviews with artists and academics.
      </Typography>
      <Typography className={classes.paragraph}>
        Our current primary focus is to develop and support the platform Ceramic
        World Destinations (CWD). CWD increases visibility for ceramics,
        provides professional exposure, and introduces new populations to the
        expressive nature of clay.
      </Typography>
      <Typography variant="h3" className={classes.header}>
        About Us
      </Typography>
      <Typography className={classes.paragraph}>
        Ceramic World Destinations has been created to reveal the global,
        diverse, and universal appeal of clay. At a time of restricted travel,
        the thousands of destinations listed become an alternative means for
        “travel,” opening new opportunities, prospects, and inspirations.
      </Typography>
      <Typography className={classes.paragraph}>
        Destinations include museums, galleries, stores, manufacturers, local
        events, institutions, cultural sites, and other ceramic-oriented venues.
        Also included are artist residency programs and artist's studios open to
        the public.
      </Typography>
      <Typography className={classes.paragraph}>
        It is simple to navigate. A map of the world with URL links as markers
        is shown upon opening. One can then navigate the map directly or go to
        search boxes to narrow the choices. No destination is ever more than a
        few clicks away.
      </Typography>
      <Typography className={classes.paragraph}>
        You may submit suggested sites for ceramic destinations by using this
        link:{" "}
        <a href="https://www.tinyurl.com/iaccwd">
          https://www.tinyurl.com/iaccwd
        </a>
      </Typography>
      <Typography variant="h3" className={classes.header}>
        Board
      </Typography>
      <br />
      <Typography className={classes.name}>Judith Schwartz</Typography>
      <Typography className={classes.title}>President</Typography>
      <Typography className={classes.paragraph}>
        Judith S Schwartz, Ph.D. is a professor emeritus from New York
        University where she taught for more than 30 years. She is the author of
        “Confrontational Ceramics” and has written numerous artist reviews,
        catalog essays, and articles in international journals. She serves as
        juror and consultant to various international competitions and is Vice
        president of the International Academy of Ceramics. She is an honorary
        member of the National Council on Education in the Ceramic Arts and was
        awarded their teaching excellence award. She is honored by the J. D.
        Rockefeller III Foundation, Fulbright Association, New York Art Teachers
        Association, and the Everson and Renwick Museums.{" "}
        <a href="https://www.judyschwartz.com/">
          https://www.judyschwartz.com/
        </a>
      </Typography>
      <Typography className={classes.name}>Bruce M Sherman</Typography>
      <Typography className={classes.paragraph}>
        Bruce M Sherman has a studio practice in New York City and Sagaponock
        Long Island. He has participated in numerous group and solo exhibitions
        worldwide and is represented by the Nicelle Beauchene Gallery in Tribeca
        NYC. He is an avid art collector with an array of works encompassing
        many disciplines. In addition to his ceramic practice, Bruce has retired
        from his dental practice and from teaching at NYU College of Dentistry,
        however, he’s still active as the CEO of the Dental Insurance
        Corporation. Bruce’s interest in art is based on his personal quest to
        explore clay’s endless possibilities for expressing ideas in sculptural
        forms. He has been a board member since MoCA/NY’s founding.{" "}
        <a href="mailto: Studio@brucemsherman.com"> Studio@brucemsherman.com</a>
      </Typography>
      <Typography className={classes.name}>Richard Rogers</Typography>
      <Typography className={classes.paragraph}>
        Richard Rogers holds an MBA from Ohio State University (1979) and a BA
        in Biology and Chemistry from Hiram College (1977). Rick began his
        career in 1980 as founder and president of Tribute, a software company
        based in Akron, Ohio. He assumed leadership of B. W. Rogers Company, a
        manufacturing and distribution company in 1994. Rick’s philanthropy
        includes supporting the Akron Children’s Hospital; the Akron Art Museum;
        the Intermuseum Conservancy Association; and the Boys and Girls Club of
        the Western Reserve. In 2017, Rick created the art nonprofit Curated
        Storefront which has secured and managed funds to launch installations
        in downtown Akron. Rick is deeply engaged in the arts and is a leading
        collector of contemporary ceramics from Asia and the US and
        twentieth-century American and European design.
      </Typography>
      <Typography className={classes.name}>Will Resen</Typography>
      <Typography className={classes.title}>Director</Typography>
      <Typography className={classes.paragraph}>
        Originally hailing from Austin, Texas, Will Resen is responsible for the
        development and maintenance of the Ceramic World Destinations web
        application. He is a graduate of the University of North Carolina at
        Chapel Hill and currently resides in Mamaroneck, New York.
      </Typography>
      <Typography variant="h3" className={classes.header}>
        Team
      </Typography>
      <br />
      <Typography className={classes.name}>Ilsy Jeon</Typography>
      <Typography className={classes.title}>
        Creative Director and Chief Content Officer
      </Typography>
      <Typography className={classes.paragraph}>
        Ilsy Jeon is a multidisciplinary artist and writer born and based in New
        York. She studied drawing and painting at Pratt Institute for two years
        and received her B.F.A Hons in Sculpture and Environmental Art from
        Glasgow School of Art where she completed a dissertation about the
        epistemology of museums through a postcolonial lens. She has
        participated in residencies in Iceland, Hungary, and the United States
        and has exhibited in New York, Glasgow, Seydisfjordur, and Budapest.
        Ilsy was a writer and editor at Artefuse, has published with The News
        Station, and is a copywriter for galleries in NYC.
      </Typography>
      <Typography className={classes.name}>Jacqueline Tse</Typography>
      <Typography className={classes.title}>
        Chief Experience Officer
      </Typography>
      <Typography className={classes.paragraph}>
        Jacqueline Tse is an artist and designer. She was awarded the
        Multicultural Fellowship in 2020 from the National Council on Education
        in the Ceramic Arts. Her work was selected for the 61st Faenza Biennale
        at the International Museum of Ceramics in Faenza, Italy, and has been
        featured in various publications about contemporary ceramic art. She is
        also a seasoned designer with over 15 years of experience in design
        consulting, development, and project management in branded consumer
        products. As a global citizen and tech-savvy individual, she is excited
        to work with MoCA/NY to enhance and enrich the digital experience of
        Ceramic World Destinations to all the ceramic art lovers out there.
      </Typography>
    </div>
  );

  return content;
};

export default About;
