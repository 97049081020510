import React from "react";
import { DialogButton } from "../components/Dialog";

export const Submit = (props) => {
  const { open, toggle } = props;
  return (
    <DialogButton
      open={open}
      toggle={toggle}
      label="Submit"
      title="Submit a Destination"
      content={
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrDxzxahvslUlPGj?backgroundColor=pink"
          frameBorder="0"
          width="100%"
          title="submission"
          style={{
            background: "transparent",
            height: "80vh",
            borderBottom: "1px solid lightgray",
          }}
        ></iframe>
      }
    />
  );
};

export default Submit;
