import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useContext } from "react";
import { isMobile } from "../util/screenSizeHelpers";
import { store } from "../util/store";

const useStyles = makeStyles({
  content: {
    overflow: "auto",
    padding: 0,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widthSm: {
    maxWidth: (props) => (props.mobile ? "100%" : "50%"),
  },
});

export const DialogButton = (props) => {
  const { open, toggle, title, content } = props;
  const { state } = useContext(store);
  const { screenSize } = state;
  const mobile = isMobile(screenSize);

  const classes = useStyles({ mobile });
  return (
    <Dialog
      open={open}
      onClose={toggle}
      fullWidth={true}
      fullScreen={mobile}
      classes={{ paper: classes.paper, paperWidthSm: classes.widthSm }}
    >
      <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={toggle}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {content}
      </DialogContent>
    </Dialog>
  );
};
