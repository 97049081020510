import React from "react";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import { StateProvider } from "./util/store";
import Router from "./Router";
// import { Footer } from "./footer/Footer";
import { Banner } from "./banner/Banner";
import { SubscribeBar } from "./banner/SubscribeBar";

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "black",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.5rem",
      },
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: "100%",
  },
});

// wrap the app in the state and theme providers
const App = () => {
  const classes = useStyles();
  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <div className={classes.container}>
          <Banner />
          <Router />
          {/* <Footer /> */}
          <SubscribeBar />
        </div>
      </ThemeProvider>
    </StateProvider>
  );
};

export default App;
