export const terracottaStyle = {
  styles: [
    {
      featureType: "landscape",
      stylers: [
        {
          color: "#f9ddc5",
        },
        {
          lightness: -7,
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#1994bf",
        },
        {
          saturation: -69,
        },
        {
          gamma: 0.99,
        },
        {
          lightness: 43,
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ],
};

export const defaultStyle = {
  styles: [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      stylers: [
        {
          color: "#1994bf",
        },
        {
          saturation: -69,
        },
        {
          gamma: 0.99,
        },
        {
          lightness: 43,
        },
      ],
    },
  ],
}