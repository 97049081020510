import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { WorldMapBanner } from "../banner/WorldMapBanner";
import WorldMap from "../map/WorldMap";
import { isMobile } from "../util/screenSizeHelpers";
import { store } from "../util/store";

const useStyles = makeStyles({
  loadingText: {
    position: "relative",
    right: "65px",
    bottom: "45px",
    color: "gray",
  },
  circularProgressColor: {
    color: "lightblue",
  },
  circle: {
    strokeLinecap: "round",
  },
  interactiveMapContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    width: "100%",
    flex: 1,
    marginBottom: (props) => (props.mobile ? 0 : "40px"),
  },
});

export const Home = () => {
  const { state } = useContext(store);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const mobile = isMobile(state.screenSize);
  const classes = useStyles({ mobile });

  useEffect(() => {
    if (Object.keys(state.destinations).length) {
      setLocationsLoaded(true);
    }
  }, [state.destinations]);

  return (
    <div className={classes.interactiveMapContainer}>
      {!mobile && <WorldMapBanner />}
      {locationsLoaded ? (
        <WorldMap locationsLoaded={locationsLoaded} />
      ) : (
        <CircularProgress
          title="Loading"
          classes={{
            root: classes.circularProgressColor,
            circle: classes.circle,
          }}
          thickness={2.5}
          variant="indeterminate"
          size={100}
        />
      )}
    </div>
  );
};
