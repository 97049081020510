import {
  Collapse,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { ArrowRightAlt } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { postEmail } from "../util/API";
import { isMobile } from "../util/screenSizeHelpers";
import { store } from "../util/store";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    display: "flex",
    alignItems: "center",
    width: "15rem",
    height: "1.8rem",
    marginLeft: "15px",
    boxShadow: "none",
    border: "1px solid lightgray",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  text: {
    fontSize: "1rem",
    fontWeight: "bold",
    paddingBottom: "3px",
  },
  grid: {
    display: "flex",
    background: "white",
    minHeight: "40px",
    borderTop: "1px solid black",
  },
  fixed: {
    position: "fixed",
    bottom: "0",
    width: "100%",
  },
}));

export const SubscribeBar = () => {
  const [formValue, setFormValue] = useState("");
  const { state } = useContext(store);
  const mobile = isMobile(state.screenSize);
  const [subscribed, setSubscribed] = useState(false);
  const [hide, setHide] = useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    setFormValue(event.target.value);
  };

  const handleSubscribe = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(formValue);
    postEmail(formValue).then(() => {
      setFormValue("");
      setSubscribed(true);
      setTimeout(() => {
        setHide(true);
      }, 5000);
    });
  };

  return !mobile ? (
    <Collapse in={!hide} className={classes.fixed}>
      <div className={classes.grid}>
        <div className={classes.container}>
          {subscribed ? (
            <span className={classes.text}>Thank you for subscribing!</span>
          ) : (
            <>
              <span className={classes.text}>Subscribe for updates</span>
              <Paper className={classes.root}>
                <InputBase
                  className={classes.input}
                  placeholder="Type email here"
                  inputProps={{ "aria-label": "type email here" }}
                  onChange={handleChange}
                  value={formValue}
                />
                <IconButton
                  className={classes.iconButton}
                  aria-label="submit"
                  onClick={handleSubscribe}
                >
                  <ArrowRightAlt />
                </IconButton>
              </Paper>
            </>
          )}
        </div>
      </div>
    </Collapse>
  ) : null;
};
