import React, { useContext, useState } from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
} from "@material-ui/core";
import { useEffect } from "react";
import { Actions, store } from "../util/store";
import { chipColors } from "./DestinationCard";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    backgroundColor: "white",
    marginBottom: "10px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    marginLeft: "15px",
    marginRight: "15px",
    marginBottom: "5px",
  },
  noFocusHighlight: {
    "&:focus": { backgroundColor: "white" },
    display: "flex",
  },
  img: {
    marginRight: "15px",
  },
});

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const Filters = (props) => {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const { dispatch, state } = useContext(store);

  useEffect(() => {
    dispatch({ type: Actions.SET_TYPE_FILTER, payload: selectedTypes });
  }, [dispatch, selectedTypes]);

  useEffect(() => {
    dispatch({ type: Actions.SET_COUNTRY_FILTER, payload: selectedCountry });
  }, [dispatch, selectedCountry]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedTypes(event.target.value);
  };

  const handleDeleteChip = (value) => () => {
    setSelectedTypes(selectedTypes.filter((type) => type !== value));
  };

  const typeRenderer = (selected) => (
    <div className={classes.chips}>
      {selected.map((value) => (
        <Chip
          key={value}
          label={value}
          className={classes.chip}
          style={{ backgroundColor: chipColors[state.types.indexOf(value)] }}
          onDelete={handleDeleteChip(value)}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        />
      ))}
    </div>
  );

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel>Country</InputLabel>
        <Select
          classes={{ select: classes.noFocusHighlight }}
          MenuProps={menuProps}
          value={selectedCountry}
          defaultValue=""
          onChange={handleCountryChange}
        >
          <MenuItem key="" value="">
            <ListItemText primary="No filter" />
          </MenuItem>
          {state?.countries?.map((country) => (
            <MenuItem key={country} value={country}>
              <img
                src={`/images/flags/${country
                  .toLowerCase()
                  .replace(/ /g, "-")}.png`}
                alt={`${country}_flag_icon`}
                height="30px"
                className={classes.img}
              />
              <ListItemText primary={country} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Type</InputLabel>
        <Select
          MenuProps={menuProps}
          classes={{ select: classes.noFocusHighlight }}
          multiple={true}
          value={selectedTypes}
          onChange={handleTypeChange}
          renderValue={typeRenderer}
        >
          {state?.types?.map((type, index) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={selectedTypes.indexOf(type) > -1} />
              <ListItemText
                primary={
                  <Chip
                    style={{
                      backgroundColor: chipColors[state.types.indexOf(type)],
                    }}
                    classes={{ root: classes.chip }}
                    key={type + index}
                    label={type}
                  />
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Filters;
