import React, { useContext, useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  makeStyles,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Typography,
  Card,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { store } from "../util/store";
import { isMobile } from "../util/screenSizeHelpers";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    justifyContent: "center",
    alignItems: "center",
  },
  formControl: {
    marginBottom: "25px",
  },
  margins: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: "100px",
    paddingTop: "0px",
    textAlign: "center",
    marginTop: "5%",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  icon: {
    width: "100px",
    height: "100px",
    fill: "#4caf50",
    marginBottom: "25px",
  },
  confirm: {
    textAlign: "center",
  },
  title: {
    fontStyle: "italic",
  },
  name: {
    fontWeight: "bold",
  },
  contactCard: {
    marginRight: "15px",
    width: "240px",
    padding: "15px",
    marginBottom: (props) => (props.mobile ? "25px" : "0px"),
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexDirection: "column",
    height: "100%",
    fontSize: "1rem",
  },
  header: {
    fontWeight: "bold",
    fontSize: "150%",
    margin: "2%",
    textAlign: "left",
  },
  headerWrapper: {
    display: "flex",
  },
  alignLeft: {
    textAlign: "left",
  },
  mobileCards: {
    display: "flex",
    flexDirection: "column",
  },
});

const Contact = () => {
  const { state } = useContext(store);
  const [topic, setTopic] = useState("");
  const [body, setBody] = useState("");
  const [opened, setOpened] = useState(false);
  const mobile = isMobile(state.screenSize);
  const classes = useStyles({ mobile });

  const openMail = () => {
    setOpened(true);
    window.location = `mailto:cwd@moca-ny.org?subject=CWD: ${topic}&body=${encodeURIComponent(
      body
    )}`;
  };

  const handleChange = (event) => {
    setTopic(event.target.value);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  useEffect(() => {
    setTopic("");
    setBody("");
    setOpened(false);
  }, []);

  return (
    <div className={classes.wrapper}>
      {/* <div className={classes.alignLeft}>
        <Typography className={classes.header}>Contact The Team</Typography>
      </div> */}
      <div className={mobile ? classes.mobileCards : classes.cardsContainer}>
        <Card className={classes.contactCard}>
          <Typography className={classes.name}>Judith Schwartz</Typography>
          <Typography className={classes.title}>President</Typography>
          <a href="mailto: judy@moca-ny.org">judy@moca-ny.org</a>
        </Card>
        <Card className={classes.contactCard}>
          <Typography className={classes.name}>Will Resen</Typography>
          <Typography className={classes.title}>
            Chief Technology Officer
          </Typography>
          <a href="mailto: will@moca-ny.org">will@moca-ny.org</a>
        </Card>
        <Card className={classes.contactCard}>
          <Typography className={classes.name}>Ilsy Jeon</Typography>
          <Typography className={classes.title}>
            Creative Director and Chief Content Officer
          </Typography>
          <a href="mailto: ilsy@moca-ny.org">ilsy@moca-ny.org</a>
        </Card>
        <Card className={classes.contactCard}>
          <Typography className={classes.name}>Jacqueline Tse</Typography>
          <Typography className={classes.title}>
            Chief Experience Officer
          </Typography>
          <a href="mailto: jackie@moca-ny.org">jackie@moca-ny.org</a>
        </Card>
      </div>
      <div className={classes.container}>
        {opened ? (
          <div className={classes.wrapper}>
            <CheckCircleOutlineIcon className={classes.icon} />
            <Typography className={classes.confirm}>
              Your message has been launched in your local email client.
            </Typography>
            <br />
            <Typography variant="subtitle2">
              If you are encountering problems, please send an email directly to
              cwd@moca-ny.org
            </Typography>
          </div>
        ) : (
          <div className={classes.margins}>
            <FormControl className={classes.formControl}>
              <InputLabel>Please select a topic *</InputLabel>
              <Select required={true} value={topic} onChange={handleChange}>
                <MenuItem value="Incorrect Info">
                  A destination has incorrect information.
                </MenuItem>
                <MenuItem value="Missing Destination">
                  My destination is not listed on the map.
                </MenuItem>
                <MenuItem value="Terms Violation">
                  A destination violates this site's Terms and Conditions.
                </MenuItem>
                <MenuItem value="Innappropiate Content">
                  I would like to report inappropriate content.
                </MenuItem>
                <MenuItem value="Bug Report">I have a bug to report.</MenuItem>
                <MenuItem value="other">My topic is not listed here.</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required={true}
              multiline={true}
              label="Please provide as much information as possible"
              onChange={handleBodyChange}
              value={body}
              rows={20}
              variant="outlined"
            />
            <Button variant="contained" onClick={openMail} color="primary">
              Launch Mail
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
