import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { DialogButton } from "../components/Dialog";

const useStyles = makeStyles({
  container: {
    padding: "10px 15%",
    height: "100%",
    borderBottom: "1px solid lightgray",
    backgroundColor: "white",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  paragraph: {},
});

const Terms = (props) => {
  const { open, toggle } = props;
  const classes = useStyles();
  const content = (
    <div className={classes.container}>
      <Typography paragraph={true}>
        By using this site you agree to the following Terms of Use. Any
        violation of this agreement may result in the ceasure of services and
        pursuit of legal action. We may, at any time, and at our sole
        discretion, modify these Terms and Conditions of Use, including our
        Privacy Policy, with or without notice to the User. Any such
        modification will be effective immediately upon public posting. Your
        continued use of our Service and this Site following any such
        modification constitutes your acceptance of these modified Terms.
      </Typography>
      <h2>Content</h2>
      <Typography paragraph={true}>
        1. The organization of data offered by this site is the sole property of
        MOCA-NY and may not be used without explicit authorization. <br /> 2.
        The data found within may not be saved or reused for purposes other than
        caching to improve the performance of this site.
      </Typography>
      <h2>Services Provided by Google</h2>
      <Typography paragraph={true}>
        This site makes use of the following services provided by Google:
        <br /> <span className={classes.indent}>1. Maps API</span>
        <br />
        <span className={classes.indent}>2. Geocoding API</span> <br />
        <span className={classes.indent}>3. Places API </span>
        <br />
        Use of this site is subject to the the{" "}
        <a href="https://maps.google.com/help/terms_maps.html">
          Google Maps/Google Earth Additional Terms of Service
        </a>{" "}
        and the{" "}
        <a href="https://www.google.com/policies/privacy/">
          Google Privacy Policy.
        </a>
      </Typography>
    </div>
  );

  return (
    <DialogButton
      open={open}
      toggle={toggle}
      label="Terms & Conditions"
      title="Terms & Conditions"
      content={content}
    />
  );
};

export default Terms;
