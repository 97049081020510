import React, { createContext, useReducer } from "react";
import update from "immutability-helper";

const initialState = {
  destinations: [],
  countries: [],
  types: [],
  typeFilter: [],
  countryFilter: [],
  searchTerm: "",
  screenSize: "md",
  showFooter: false,
};

const Actions = {
  GET_DATA: "GET_DATA",
  SET_TYPE_FILTER: "SET_TYPE_FILTER",
  SET_COUNTRY_FILTER: "SET_COUNTRY_FILTER",
  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  SET_SCREEN_SIZE: "SET_SCREEN_SIZE",
  SET_SHOW_FOOTER: "SET_SHOW_FOOTER",
};

const store = createContext(initialState);
const { Provider } = store;

// poor man's redux
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case Actions.GET_DATA:
        return update(state, {
          destinations: { $set: action.payload.destinations },
          types: { $set: action.payload.types },
          countries: { $set: action.payload.countries },
        });
      case Actions.SET_TYPE_FILTER:
        return update(state, { typeFilter: { $set: action.payload } });
      case Actions.SET_COUNTRY_FILTER:
        return update(state, { countryFilter: { $set: action.payload } });
      case Actions.SET_SEARCH_TERM:
        return update(state, { searchTerm: { $set: action.payload } });
      case Actions.SET_SCREEN_SIZE:
        return update(state, { screenSize: { $set: action.payload } });
      case Actions.SET_SHOW_FOOTER:
        return update(state, { showFooter: { $set: action.payload } });
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, Actions };
