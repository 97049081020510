import React from "react";
import { makeStyles } from "@material-ui/core";
import { Logo } from "./Logo";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  navbar: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    background: "white",
    alignItems: "center",
    boxShadow: "0px 0px 5px 0px #000000c2",
  },
  bannerContainer: {
    minHeight: "90px",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: "240px",
    paddingLeft: "40px",
  },
  link: {
    fontSize: "1.1rem",
    fontWeight: 400,
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
    "&:hover": {
      color: "lightgray",
    },
  },
  list: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    listStyle: "none",
    width: "100%",
  },
  donateLink: {
    fontWeight: 400,
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#A84228",
    "&:hover": {
      color: "lightgray",
    },
    fontSize: "1.1rem",
  },
});

export const DesktopAppBanner = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.navbar}>
        <Logo name={"moca"} className={classes.logo} />
        <div className={classes.list}>
          <Link className={classes.link} to="/">
            Home
          </Link>
          <Link className={classes.link} to="/about">
            About Us
          </Link>
          <a
            className={classes.donateLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.paypal.com/donate/?hosted_button_id=6L7SXJKSNDJ8Y"
          >
            Donate
          </a>
          <Link className={classes.link} to="/exhibitions">
            Exhibitions
          </Link>
        </div>
      </div>
    </div>
  );
};
