import React, { useState, useCallback, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import debounce from "lodash/debounce";
import { store } from "../util/store";
import { Typography } from "@material-ui/core";
import Filters from "./Filters";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    alignItems: "center",
    minHeight: "fit-content",
    marginBottom: "10px",
    marginRight: "10px",
    marginLeft: "10px",
    borderRadius: "15px",
    boxShadow: "0px 1px 4px #bebebe3d",
    border: "1px solid #ebebeb",
  },
  input: {
    marginLeft: 15,
    flex: "1 1 auto",
    padding: 0,
    "&::placeholder": {
      color: "black",
      fontWeight: 100,
      opacity: 0.6,
    },
  },
  showFilter: {
    maxHeight: "500px",
    width: "100%",
    transition: "max-height .4s ease-out",
  },
  hideFilter: {
    maxHeight: "0px",
    width: "100%",
    overflow: "hidden",
    transition: "max-height .3s ease-in",
  },
  search: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  collapseIcon: {
    display: "flex",
    margin: "0 auto",
  },
  filterLabel: {
    display: "flex",
    justifyContent: "center",
    margin: "15px",
    marginBottom: "0px",
  },
  iconButton: {
    fontSize: "1.5rem",
  },
});

export const Searchbar = (props) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const { dispatch } = useContext(store);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const applySearchTerm = useCallback(
    debounce((value) => {
      dispatch({ type: "SET_SEARCH_TERM", payload: value });
    }, 500),
    []
  );

  const handleChange = (event) => {
    event.stopPropagation();
    const val = event.target.value;
    setSearchTerm(val);
    applySearchTerm(val.trim());
  };

  const handleClear = () => {
    setSearchTerm("");
    applySearchTerm("");
  };

  const hideFilters = (event) => {
    setFiltersOpen(false);
  };

  const showFilters = (event) => {
    setFiltersOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.search} onClick={showFilters}>
        <InputBase
          placeholder="Where to?"
          className={classes.input}
          onChange={handleChange}
          value={searchTerm}
          inputProps={{
            className: classes.input,
          }}
          spellCheck={false}
        />
        <IconButton className={classes.iconButton} onClick={handleClear}>
          {searchTerm.length ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
      </div>
      <div
        className={
          filtersOpen === true ? classes.showFilter : classes.hideFilter
        }
      >
        <Divider />
        <Typography variant="subtitle2" className={classes.filterLabel}>
          Filter by...
        </Typography>
        <Filters />
        <IconButton onClick={hideFilters} className={classes.collapseIcon}>
          <ArrowUpwardRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
};
