import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  logo: {
    width: "230px",
    marginLeft: "25px",
    marginTop: "25px",
  },
});

export const Logo = (props) => {
  const { name, className } = props;
  const classes = useStyles();
  return (
    <Link to="/">
      <img
        src={`./images/${name}_logo.png`}
        alt={name}
        className={className || classes.logo}
      />
    </Link>
  );
};
