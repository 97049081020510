import React, { useContext, useEffect } from "react";
import { store, Actions } from "../util/store";
import { API } from "../util/API";
import { getScreenSize, isMobile } from "../util/screenSizeHelpers";
import { useLayoutEffect } from "react";
import { MobileAppBanner } from "./MobileAppBanner";
import { DesktopAppBanner } from "./DesktopAppBanner";

export const Banner = (props) => {
  const { dispatch, setRetrievedPageCount } = useContext(store);

  window.addEventListener("resize", () => {
    dispatch({
      type: Actions.SET_SCREEN_SIZE,
      payload: getScreenSize(window.innerWidth),
    });
  });

  useLayoutEffect(() => {
    dispatch({
      type: Actions.SET_SCREEN_SIZE,
      payload: getScreenSize(window.innerWidth),
    });
  }, [dispatch]);

  useEffect(() => {
    API.getAllFromDatabase(setRetrievedPageCount).then((data) => {
      dispatch({
        type: Actions.GET_DATA,
        payload: data,
      });
    });
  }, [dispatch, setRetrievedPageCount]);

  return isMobile(getScreenSize(window.innerWidth)) ? (
    <MobileAppBanner />
  ) : (
    <DesktopAppBanner />
  );
};
