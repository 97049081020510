import React, { useState } from "react";
import { Drawer, IconButton, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import { Close } from "@material-ui/icons";
import Submit from "../pages/Submit";
import SubmitCriteria from "../pages/SubmitCriteria";

const useStyles = makeStyles({
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    background: "white",
    boxShadow: "0px 1px 4px #00000030",
  },
  mobileList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    listStyle: "none",
    padding: "0px",
    fontSize: "150%",
  },
  logo: {
    width: "140px",
  },
  cwdLogo: {
    width: "100px",
    padding: "10px",
    display: "flex",
  },
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
  },
  listItem: {
    margin: "15px",
  },
  icon: {
    fontSize: "2rem",
    color: "black",
  },
  donateLink: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#A84228",
  },
});

export const MobileAppBanner = (props) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={classes.navbar}>
      <Drawer onClose={toggleDrawer} anchor="top" open={drawerOpen}>
        <NavBar toggleDrawer={toggleDrawer} />
      </Drawer>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon className={classes.icon} />
      </IconButton>
      <Logo name={"moca"} className={classes.logo} />
      <Logo name={"cwd"} className={classes.cwdLogo} />
    </div>
  );
};

const NavBar = (props) => {
  const { toggleDrawer } = props;
  const classes = useStyles();

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submitCriteriaOpen, setCriteriaOpen] = useState(false);

  const toggleSubmit = () => {
    setSubmitDialogOpen(!submitDialogOpen);
  };

  const toggleSubmissionCriteria = () => {
    setCriteriaOpen(!submitCriteriaOpen);
  };

  return (
    <>
      <Submit open={submitDialogOpen} toggle={toggleSubmit} />
      <SubmitCriteria
        open={submitCriteriaOpen}
        toggle={toggleSubmissionCriteria}
      />
      <ul className={classes.mobileList}>
        <li className={classes.listItem} onClick={toggleDrawer}>
          <Link className={classes.link} to="/">
            Home
          </Link>
        </li>
        <li className={classes.listItem} onClick={toggleDrawer}>
          <Link className={classes.link} to="/about">
            About Us
          </Link>
        </li>
        <li className={classes.listItem}>
          <span className={classes.link} onClick={toggleSubmit}>
            Submit a Destination
          </span>
        </li>
        <li className={classes.listItem} onClick={toggleSubmissionCriteria}>
          <span className={classes.link}>Submission Criteria</span>
        </li>
        <li className={classes.listItem} onClick={toggleDrawer}>
          <a
            className={classes.donateLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.paypal.com/donate/?hosted_button_id=6L7SXJKSNDJ8Y"
          >
            Donate
          </a>
        </li>
      </ul>
      <IconButton onClick={toggleDrawer}>
        <Close className={classes.icon} />
      </IconButton>
    </>
  );
};
