import Airtable from "airtable";
import { startCase } from "lodash";

const base = new Airtable({ apiKey: "keypJvipCeJe48vub" }).base(
  "app35IWtpc9hCQXLT"
);

const emailBase = new Airtable({ apiKey: "keypJvipCeJe48vub" }).base("appU6lLvXLqCrpvnn");

export const postEmail = async (record) => {
  return new Promise((resolve, reject) => {
    emailBase("Table 1").create({ email: record }, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
};

const sanitize = (text) => {
  return startCase(text.toLowerCase())
    .replace("&", "and")
    .replace("\\n", "")
    .trim();
};

export const API = {
  // Retrieve all entries from the database.
  // original: determines which result format to return (minimized or all data). if true, returns an array of destination objects. if false, returns a nested object.
  getAllFromDatabase: (setRetrievedPageCount) => {
    const destinations = [];
    const countries = {};
    const types = {};

    return new Promise((resolve) => {
      base("Table 1")
        .select({
          view: "Grid view",
          fields: [
            "Country",
            "Name",
            "City",
            "URL",
            "Longitude",
            "Latitude",
            "State/Province",
            "Type",
            "Lock Coordinates",
            "Description",
            "Sponsored",
          ],
        })
        .eachPage((records, fetchNextPage) => {
          records.forEach((record) => {
            const country = record.fields.Country
              ? sanitize(record.fields.Country)
              : undefined;
            const province = record.fields["State/Province"]
              ? sanitize(record.fields["State/Province"])
              : undefined;
            const city = record.fields.City
              ? sanitize(record.fields.City)
              : undefined;
            const name = record.fields.Name
              ? sanitize(record.fields.Name)
              : undefined;
            const url = record.fields.URL
              ? record.fields.URL.trim()
              : undefined;
            const description = record.fields.Description;
            const lockCoordinates = record.fields["Lock Coordinates"];
            const type = record.fields.Type;
            const sponsored = record.fields.Sponsored;

            if (country && city && name && url) {
              destinations.push({
                name,
                city,
                country,
                url,
                province,
                type,
                description,
                sponsored,
                id: record.id,
                lng: Number(record.fields.Longitude),
                lat: Number(record.fields.Latitude),
                lockCoordinates: lockCoordinates,
              });
              countries[country] = true;
              type && type.forEach((type) => (types[type] = true));
            }
          });
          // setRetrievedPageCount(count => count + 1);
          fetchNextPage();
        })
        .then(() => {
          resolve({
            destinations,
            types: Object.keys(types),
            countries: Object.keys(countries).sort(),
          });
        });
    });
  },

  // Update the Airtable record with the coordinates retrieved from Google Geocode
  updateRecord: (id, lat, lng) => {
    base("Table 1").update(
      id,
      { Longitude: lng.toString(), Latitude: lat.toString() },
      { typecast: false },
    );
  },

  // Retrieve a single marker from Google Geocode API
  // location object: { name: string, city: string, country: string }
  getMarker: (location) => {
    const locationString = (
      location.name +
      ", " +
      location.city +
      ", " +
      location.country
    ).replace(" ", "+");
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${locationString}&key=AIzaSyC5JsdNt6b3_nV8aLtPKLG9dn6Mi-neIYU`
      )
        .then((response) => response.json())
        .then((response) => {
          response =
            response &&
            response.results &&
            response.results[0] &&
            response.results[0].geometry &&
            response.results[0].geometry.location;
          response && API.updateRecord(location.id, response.lat, response.lng);
          resolve({ ...location, ...response });
        })
        .catch(reject);
    });
  },

  // Retrieve/refresh coordinates for markers in the database.
  // locations array: { name: string, city: string, country: string }[]
  // onlyNew: if true, only retrieve coordinates for locations that do not already have them
  updateMarkers: (locations, onlyNew = false) => {
    const getMarkersWithDelay = (location, delay) => {
      console.log(location, delay);
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          API.getMarker(location).then(resolve).catch(reject);
        }, delay);
      });
    };

    const getMarkers = (locations) => {
      // do not send more than 50 requests per second to the Geocoding API; this sends one request every 350ms.
      let delay = 0;
      Promise.all(
        locations.map((location) => {
          if (location.lockCoordinates) {
            //skip coordinates that have been locked in Airtable
            return Promise.resolve();
          }

          if (onlyNew) {
            if (!!!location.lat || !!!location.lng) {
              delay += 350;
              console.log(location);
              return getMarkersWithDelay(location, delay);
            } else {
              return Promise.resolve();
            }
          } else {
            delay += 350;
            return getMarkersWithDelay(location, delay);
          }
        })
      ).then((response) => {
        !onlyNew && localStorage.setItem("markers", JSON.stringify(response));
      });
    };

    getMarkers(locations);
  },
};

export const mergeDatabases = () => {
  // TODO:
  // retrieve all entries from submissions database and store in an array
  // upon completion, iterate through array of pendingEntries and insert each one into main database
};
