import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { DialogButton } from "../components/Dialog";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "4%",
    color: "black",
  },
  header: {
    fontWeight: "bold",
    marginBottom: "10px",
    textTransform: "uppercase",
    textAlign: "center",
    textDecoration: "underline",
  },
  underline: {
    textDecoration: "underline",
    lineHeight: "1.5rem",
  },
  category: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  noMargin: {
    margin: "0",
  },
  ordered: {
    padding: "15px",
  },
  orderedListItem: {
    paddingLeft: "15px",
  },
}));

const Category = (props) => {
  const { title, items } = props;
  const classes = useStyles();

  return (
    <>
      <span className={classes.category}>{title}:</span>
      <ul className={classes.noMargin}>
        {items.map((item, index) => (
          <li key={item[0] + index}>
            <Typography>{item}</Typography>
          </li>
        ))}
      </ul>
      <br />
    </>
  );
};

const CriteriaBody = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.header}>
        Ceramic World Destinations Submission Criteria:
      </Typography>
      Destinations will be vetted in accordance with the following criteria and
      must meet the standard and quality of MoCA/NY.
      <br />
      <ol className={classes.ordered}>
        <li className={classes.orderedListItem}>
          <b>
            Must have a publicly accessible physical location. Locations that
            are “by appointment only” are permitted if booking information is
            available on the website.
          </b>
        </li>
        <li className={classes.orderedListItem}>
          Only destinations with an online presence will be listed.
        </li>
        <li className={classes.orderedListItem}>
          The website must include contact and visiting information.
        </li>
        <li className={classes.orderedListItem}>
          Submit primary and secondary types of destination. Only 2 types are
          accepted.
        </li>
      </ol>
      <br />
      <Typography variant="h3" className={classes.header}>
        Definition of Destination Type:
      </Typography>
      <br />
      <Category
        title="Artist"
        items={[
          "A devoted practitioner with personal style and commitment to excellence.",
          "Website contains an accurate representation of artistic vision, portfolio, and CV.",
        ]}
      />
      <Category
        title="Cultural Heritage Site"
        items={[
          "Historical venues: architectural, archeological, geological, and monumental sites related to ceramics.",
        ]}
      />
      <Category
        title="Gallery"
        items={[
          "Exhibitions and representation of ceramic artworks and artists.",
        ]}
      />
      <Category
        title="Institution"
        items={[
          "An institution with an educational focus and curriculum dedicated to the practice and professional advancement of ceramic art.",
        ]}
      />
      <Category
        title="Manufacturer"
        items={[
          "A person, group, or factory with ceramic manufacturing and production capabilities.",
        ]}
      />
      <Category
        title="Museum"
        items={[
          "Exhibitions and collections that include ceramic art and artists.",
        ]}
      />
      <Category
        title="Organization"
        items={[
          "An association, network, or group that engages and sustains a community for the ceramic arts.",
        ]}
      />
      <Category
        title="Residency"
        items={[
          "A dedicated destination providing time and space to work with clay.",
        ]}
      />
      <Category
        title="Store"
        items={[
          "A retail establishment that presents ceramic design and supplies.",
        ]}
      />
      <Category
        title="Studio"
        items={[
          "Ceramic studios that are accessible to the public and provide memberships, rental, classes, and/or firing services.",
        ]}
      />
    </div>
  );
};

export const SubmitCriteria = (props) => {
  const { open, toggle } = props;
  return (
    <DialogButton
      open={open}
      toggle={toggle}
      label="Criteria"
      title="Submission Criteria"
      content={<CriteriaBody />}
    />
  );
};

export default SubmitCriteria;
