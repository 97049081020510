import React, { useContext, useState, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { store } from "../util/store";
import { useEffect } from "react";
import { defaultStyle, terracottaStyle } from "./MapStyling";
import { makeStyles, Button, Chip } from "@material-ui/core";
import { isMobile } from "../util/screenSizeHelpers";
import { useLayoutEffect } from "react";
import { Searchbar } from "./Searchbar";
import { chipColors } from "./DestinationCard";
import ReactDOMServer from "react-dom/server";
import DestinationsPanel from "./DestinationsPanel";
import MarkerClusterer from "@google/markerclustererplus";

const useStyles = makeStyles({
  container: {
    height: "0px",
    flex: "1",
    display: "flex",
  },
  mobileContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  showMapButton: {
    margin: "10px",
    minHeight: "fit-content",
  },
  mapContainer: {
    width: "100%",
    height: "100%",
    display: (props) => (props.showMap && props.loaded ? "block" : "none"),
    flex: 1,
  },
  locationList: {
    display: (props) => (props.showList && props.loaded ? "flex" : "none"),
    flexDirection: "column",
    height: "100%",
    width: (props) => (isMobile(props.mobile) ? "100%" : "28%"),
    maxWidth: (props) => (isMobile(props.mobile) ? "100%" : "50%"),
    overflow: "auto",
    minWidth: "350px",
  },
  chip: {
    margin: "15px 20px",
    marginLeft: "0px",
  },
});

export const handleLinkClick = (destination) => {
  let url = destination.url;
  if (url.slice(0, 4) !== "http") {
    url = "http://" + url;
  }
  window.open(url, "__blank");
};

const center = { lat: 50, lng: 30 };
const defaultCenter = { lat: 59.95, lng: 30.33 };

const WorldMap = (props) => {
  const { state } = useContext(store);
  const [markers, setMarkers] = useState([]);
  const searchTerm = state.searchTerm.toLowerCase();
  const { countryFilter, typeFilter } = state;
  const [map, setMap] = useState(null);
  const [showList, setShowList] = useState(true);
  const [showMap, setShowMap] = useState(true);
  const [mapOptions, setMapOptions] = useState(terracottaStyle);
  let markerCluster = useRef();

  const classes = useStyles({
    showMap,
    showList,
    loaded: props.locationsLoaded,
    mobile: state.screenSize,
  });

  const handleShowMap = () => {
    setShowMap(!showMap);
    setShowList(!showList);
  };

  const applyFilter = useCallback(
    (marker) => {
      const includesTerm =
        searchTerm.length > 0
          ? marker.name.toLowerCase().includes(searchTerm) ||
            marker.city.toLowerCase().includes(searchTerm) ||
            marker?.province?.toLowerCase().includes(searchTerm) ||
            marker.country.toLowerCase().includes(searchTerm) ||
            marker.url?.toLowerCase().includes(searchTerm)
          : true;

      const includesType =
        typeFilter.length > 0
          ? marker.type?.some((type) => typeFilter.indexOf(type) >= 0)
          : true;

      const includesCountry =
        countryFilter.length > 0
          ? countryFilter.includes(marker.country)
          : true;

      return includesTerm && includesType && includesCountry;
    },
    [countryFilter, searchTerm, typeFilter]
  );

  useLayoutEffect(() => {
    setShowMap(!isMobile(state.screenSize));
    setShowList(true);
  }, [state.screenSize]);

  // switch the map style as the user zooms in to assist readability
  useEffect(() => {
    map &&
      map.addListener("zoom_changed", () => {
        if (map.getZoom() > 13) {
          setMapOptions(defaultStyle);
        } else {
          setMapOptions(terracottaStyle);
        }
      });
  }, [map]);

  /*
  Iterate through all of our AirTable data and render each marker.
  This is called only once the map is rendered and redux state has been populated with our locations.
  */
  useEffect(() => {
    if (map) {
      const marks = state.destinations;
      const allMarkers = [];
      marks &&
        marks.forEach((mark) => {
          if (isNaN(mark.lat) || isNaN(mark.lng)) {
            return null;
          }

          // create our markers
          if (window.google && state.types.length) {
            const newMarker = new window.google.maps.Marker({
              map: map,
              draggable: false,
              position: { lat: mark.lat, lng: mark.lng },
              title: `${mark.url}`,
              icon: {
                url: mark.type
                  ? mark.type.length > 1
                    ? "/images/markers/multi.png"
                    : `/images/markers/${chipColors[
                        state.types.indexOf(mark.type[0])
                      ].replace("#", "")}_${
                        mark.sponsored ? "starred" : "plain"
                      }.png`
                  : "images/marker.png",
                scaledSize: new window.google.maps.Size(35, 35),
              },
            });
            var infowindow = new window.google.maps.InfoWindow({
              content: `<strong>${mark.name}, ${mark.city}, ${
                mark.country
              }</strong><br>${ReactDOMServer.renderToStaticMarkup(
                mark.type?.sort().map((t, i) => (
                  <Chip
                    style={{
                      backgroundColor: chipColors[state.types.indexOf(t)],
                    }}
                    classes={{ root: classes.chip }}
                    label={t}
                    key={t + i}
                  />
                ))
              )} <br>${mark.url}`,
              disableAutoPan: true,
            });
            newMarker.addListener("mouseover", function () {
              infowindow.open(map, newMarker);
            });
            newMarker.addListener("mouseout", function () {
              infowindow.close(map, newMarker);
            });
            newMarker.addListener("click", function () {
              handleLinkClick(mark);
            });
            allMarkers.push({
              location: mark,
              googleMarker: newMarker,
              display: true,
            });
          }
          // end marker creation
        });
      setMarkers(allMarkers);
    }
  }, [map, state.destinations, state.types, classes.chip]);

  // handle our filters - this is called every time a filter/searchterm changes
  useEffect(() => {
    // reset our map bounds and marker clusterer
    const bounds = window.google && new window.google.maps.LatLngBounds();
    markerCluster.current && markerCluster.current.setMap(null);
    let visibleMarkerCount = 0;

    markers.forEach((marker) => {
      // filter markers
      if (applyFilter(marker.location)) {
        marker.googleMarker.setMap(map);
        bounds.extend(marker.googleMarker.position);
        marker.display = true;
        visibleMarkerCount++;
      } else {
        marker.googleMarker.setMap(null);
        marker.display = false;
      }
    });
    map && visibleMarkerCount > 0 && map.fitBounds(bounds);
    if (visibleMarkerCount === 1) {
      map.setZoom(10);
    }

    markerCluster.current = map
      ? new MarkerClusterer(
          map,
          markers
            .filter((marker) => marker.display === true)
            .map((marker) => marker.googleMarker),
          {
            imagePath: "images/markers/clusters/m",
            gridSize: 50,
            minimumClusterSize: 8,
          }
        )
      : null;

    markerCluster.current &&
      markerCluster.current.setStyles(
        markerCluster.current.getStyles().map((style) => {
          style.textColor = "#fff";
          style.fontFamily = "'Arial', sans-serif";
          style.fontWeight = "100";
          style.textSize = "12";
          return style;
        })
      );
  }, [applyFilter, map, markers, searchTerm]);

  return (
    <div
      className={
        isMobile(state.screenSize) ? classes.mobileContainer : classes.container
      }
    >
      {isMobile(state.screenSize) && (
        <Button
          variant="contained"
          onClick={handleShowMap}
          className={classes.showMapButton}
        >
          {`${showMap ? "Hide" : "Show"} Map`}
        </Button>
      )}
      <div className={classes.locationList}>
        <Searchbar />
        <DestinationsPanel markers={markers} map={map} />
      </div>
      <div className={classes.mapContainer}>
        <GoogleMapReact
          onGoogleApiLoaded={({ map }) => {
            setMap(map);
          }}
          bootstrapURLKeys={{
            key: "AIzaSyCv_7MnsDMhLAL9L7uYXUZs8rnkMmMDvGE",
          }}
          defaultCenter={defaultCenter}
          defaultZoom={0}
          center={center}
          zoom={0}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals={true}
        ></GoogleMapReact>
      </div>
    </div>
  );
};

export default WorldMap;
