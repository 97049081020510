import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Home } from "./pages/Home";
import Submit from "./pages/Submit";
import Terms from "./pages/Terms";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/submit" element={<Submit />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
};

export default Router;
